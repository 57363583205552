import React, { useState, useEffect } from 'react';

const Modal = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {

    const timer = setTimeout(() => {
      setShowModal(true);
    }, 6000);


    return () => clearTimeout(timer);
  }, []);


  return (
    <div>
      {showModal && (
        <>


          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-center min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
              <div className='fixed inset-0 bg-black bg-opacity-70 z-20'></div>
              <div className="relative z-30 transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full md:w-auto h-full md:h-[75vh]">
                <div className="bg-white w-full h-full">
                  <div className="sm:flex sm:items-start">
                    <button
                      className="absolute top-3 right-3 px-2 rounded-full bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                      id="modal-title"
                      onClick={() => setShowModal(false)}
                    >
                      <i className="fa fa-times"></i>
                    </button>

                  </div>
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/imagenes-de-stock-y-redes.appspot.com/o/neymarsbarbershop%2FNeymar's%20Barber%20Shop.jpg?alt=media&token=57696ea0-04c0-48f2-902b-139ab6c7b6f0"
                    alt="notimg"
                    loading='lazy'
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </>
      )}
    </div>
  );
}

export default Modal;
